import {
  Box,
  Flex,
  HStack,
  Text,
  Skeleton,
} from '@chakra-ui/react';
import React from 'react';

import type { Transaction } from 'types/api/transaction';

import config from 'configs/app';
import getValueWithUnit from 'lib/getValueWithUnit';
import useTimeAgoIncrement from 'lib/hooks/useTimeAgoIncrement';
import AddressEntity from 'ui/shared/entities/address/AddressEntity';
import TxEntity from 'ui/shared/entities/tx/TxEntity';
import IconSvg from 'ui/shared/IconSvg';
import TxStatus from 'ui/shared/statusTag/TxStatus';
import TxFeeStability from 'ui/shared/tx/TxFeeStability';
import TxWatchListTags from 'ui/shared/tx/TxWatchListTags';
import TxAdditionalInfo from 'ui/txs/TxAdditionalInfo';
import TxType from 'ui/txs/TxType';

import useCustomColors from '../../lib/hooks/useCustomColors';

type Props = {
  tx: Transaction;
  isLoading?: boolean;
}

const LatestTxsItem = ({ tx, isLoading }: Props) => {
  const dataTo = tx.to ? tx.to : tx.created_contract;
  const timeAgo = useTimeAgoIncrement(tx.timestamp || '0', true);

  const { bgColor, textColor } = useCustomColors();

  return (
    <Box
      width="100%"
      mt={ 1 }
      py={ 4 }
      px={ 4 }
      display={{ base: 'block', lg: 'none' }}
      backgroundColor={ bgColor }
      backdropFilter="blur(15px)"
      borderRadius="12px"
      boxShadow="0px 3px 4px #00000020"
    >
      <Flex justifyContent="space-between">
        <HStack flexWrap="wrap">
          <TxType types={ tx.tx_types } isLoading={ isLoading }/>
          <TxStatus status={ tx.status } errorText={ tx.status === 'error' ? tx.result : undefined } isLoading={ isLoading }/>
          <TxWatchListTags tx={ tx } isLoading={ isLoading }/>
        </HStack>
        <TxAdditionalInfo tx={ tx } isMobile isLoading={ isLoading }/>
      </Flex>
      <Flex
        mt={ 2 }
        alignItems="center"
        width="100%"
        justifyContent="space-between"
        mb={ 6 }
      >
        <TxEntity
          isLoading={ isLoading }
          hash={ tx.hash }
          fontWeight="700"
          truncation="constant"
        />
        { tx.timestamp && (
          <Skeleton isLoaded={ !isLoading } color={ textColor } fontWeight="400" fontSize="sm" ml={ 3 }>
            <span>{ timeAgo }</span>
          </Skeleton>
        ) }
      </Flex>
      <Flex alignItems="center" mb={ 3 }>
        <AddressEntity
          isLoading={ isLoading }
          address={ tx.from }
          truncation="constant"
          fontSize="sm"
          fontWeight="500"
          mr={ 2 }
        />
        <IconSvg
          name="tenet/arrow-right"
          mr={ 2 }
          boxSize={ 5 }
          color={ textColor }
          isLoading={ isLoading }
        />
        { dataTo && (
          <AddressEntity
            isLoading={ isLoading }
            address={ dataTo }
            truncation="constant"
            fontSize="sm"
            fontWeight="500"
            iconColor={ textColor }
            iconS={ 4 }
          />
        ) }
      </Flex>
      { !config.UI.views.tx.hiddenFields?.value && (
        <Skeleton isLoaded={ !isLoading } mb={ 2 } fontSize="sm" w="fit-content">
          <Text as="span">Value { config.chain.currency.symbol } </Text>
          <Text as="span" color="disabled">{ getValueWithUnit(tx.value).dp(5).toFormat() }</Text>
        </Skeleton>
      ) }
      { !config.UI.views.tx.hiddenFields?.tx_fee && (
        <Skeleton isLoaded={ !isLoading } fontSize="sm" w="fit-content" display="flex" whiteSpace="pre">
          <Text as="span">Fee { !config.UI.views.tx.hiddenFields?.fee_currency ? `${ config.chain.currency.symbol } ` : '' }</Text>
          { tx.stability_fee ? (
            <TxFeeStability data={ tx.stability_fee } accuracy={ 5 } color="text_secondary" hideUsd/>
          ) : (
            <Text as="span" color="disabled">{ tx.fee.value ? getValueWithUnit(tx.fee.value).dp(5).toFormat() : '-' }</Text>
          ) }
        </Skeleton>
      ) }
    </Box>
  );
};

export default React.memo(LatestTxsItem);
