import {
  Box,
  Flex,
  Grid,
  Skeleton, useColorModeValue,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import React from 'react';

import type { Block } from 'types/api/block';

import config from 'configs/app';
import getBlockTotalReward from 'lib/block/getBlockTotalReward';
import getNetworkValidatorTitle from 'lib/networks/getNetworkValidatorTitle';
import BlockTimestamp from 'ui/blocks/BlockTimestamp';
import AddressEntity from 'ui/shared/entities/address/AddressEntity';
import BlockEntity from 'ui/shared/entities/block/BlockEntity';

type Props = {
  block: Block;
  isLoading?: boolean;
}

const LatestBlocksItem = ({ block, isLoading }: Props) => {
  const totalReward = getBlockTotalReward(block);
  const textColor = useColorModeValue('secondary', 'primaryDark');
  const bgColor = useColorModeValue('white25', 'dark25');
  const border = useColorModeValue('', '1px solid #312A4080');
  return (
    <Box
      as={ motion.div }
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ display: 'none' }}
      transitionDuration="normal"
      transitionTimingFunction="linear"
      borderRadius="md"
      backgroundColor={ bgColor }
      backdropFilter="blur(15px)"
      border={ border }
      boxShadow="0px 0px 6px #00000020"
      p={ 6 }
      mt={ 3 }
    >
      <Flex alignItems="center" overflow="hidden" w="100%" mb={ 3 }>
        <BlockEntity
          isLoading={ isLoading }
          number={ block.height }
          tailLength={ 2 }
          fontSize="xl"
          lineHeight={ 7 }
          fontWeight={ 500 }
          mr="auto"
          iconColor={ textColor }
        />
        <BlockTimestamp
          ts={ block.timestamp }
          isEnabled={ !isLoading }
          isLoading={ isLoading }
          fontSize="14px"
          color={ textColor }
          flexShrink={ 0 }
          ml={ 2 }
        />
      </Flex>
      <Grid gridGap={ 2 } templateColumns="auto minmax(0, 1fr)" fontSize="sm">
        <Skeleton isLoaded={ !isLoading } color={ textColor } fontWeight={ 500 }>Txn</Skeleton>
        <Skeleton isLoaded={ !isLoading } color={ textColor }><span>{ block.tx_count }</span></Skeleton>

        { !config.features.optimisticRollup.isEnabled && !config.UI.views.block.hiddenFields?.total_reward && (
          <>
            <Skeleton isLoaded={ !isLoading } color={ textColor } fontWeight={ 500 }>Reward</Skeleton>
            <Skeleton isLoaded={ !isLoading } color={ textColor }><span>{ totalReward.dp(10).toFixed() }</span></Skeleton>
          </>
        ) }

        { !config.features.optimisticRollup.isEnabled && !config.UI.views.block.hiddenFields?.miner && (
          <>
            <Skeleton isLoaded={ !isLoading } textTransform="capitalize" color={ textColor } fontWeight={ 500 }>{ getNetworkValidatorTitle() }</Skeleton>
            <AddressEntity
              address={ block.miner }
              isLoading={ isLoading }
              noIcon
              noCopy
            />
          </>
        ) }
      </Grid>
    </Box>
  );
};

export default LatestBlocksItem;
