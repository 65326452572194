import { Alert, AlertDescription, chakra } from '@chakra-ui/react';
import React from 'react';

import useCustomColors from '../../lib/hooks/useCustomColors';

const DataFetchAlert = ({ className }: { className?: string }) => {
  const { bgColor } = useCustomColors();
  return (
    <Alert
      status="warning"
      width="fit-content"
      className={ className }
      backgroundColor={ bgColor }
      borderRadius="12px"
      boxShadow="0px 3px 4px #00000030"
      backdropFilter="blur(15px)"
    >
      <AlertDescription>
        Something went wrong. Try refreshing the page or come back later.
      </AlertDescription>
    </Alert>
  );
};

export default chakra(DataFetchAlert);
